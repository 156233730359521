import Chart from 'react-apexcharts';
import NoDataAlert from '../../../common/returns/NoDataAlert';

const ChartBarViewDefault = ({ chartDataProps, typeChart }) => {
	let data = [];
	let data2 = [];
	if (typeChart === 'cargasTermicasAmbientes') {
		data = chartDataProps ? chartDataProps.cargasTermicas : [];
		data2 = chartDataProps ? chartDataProps.cargasTermicas : [];
	}

	const series = [
		{
			name: 'Carga termica efetiva dos Evaporadores ( Kcal/ h )',
			data: data2,
			color: '#02FFA9',
		},
		{
			name: 'Carga termica necessária do Ambiente ( Kcal/ h )',
			data: data,
			color: '#0CABF5',
		},
	];

	if (!chartDataProps || chartDataProps === undefined)
		return (
			<>
				<div className="">
					<NoDataAlert />
				</div>
			</>
		);

	const options = {
		chart: {
			stacked: false,
			toolbar: {
				show: false,
			},
		},
		grid: {
			show: false,
			borderColor: '#40475D',
			strokeDashArray: 0,
		},
		plotOptions: {
			bar: {
				borderRadius: 5,
				/* borderRadiusApplication: 'end',
				borderRadiusWhenStacked: 'last', */
				columnWidth: '100%',
				horizontal: true,
				dataLabels: {
					position: 'center',
				},
			},
		},
		dataLabels: {
			enabled: true,
			textAnchor: 'middle', //
			style: {
				colors: ['#FFF'],
				fontSize: '14px',
			},
			offsetY: 0,
			offsetX: 30,
			formatter: function (val, opts) {
				let value = val?.toLocaleString('pt-BR', {
					maximumFractionDigits: 1,
				});
				return ` ${value} Kcal/ h`;
			},
			dropShadow: {
				enabled: true,
				left: 2,
				top: 2,
				opacity: 0.5,
			},
		},
		stroke: {
			width: 1,
			colors: ['#33333380'],
		},
		xaxis: {
			categories: chartDataProps ? chartDataProps.ambientes : [],
			position: 'bottom',
			axisBorder: {
				show: false,
			},
			axisTicks: {
				show: false,
				color: '#40475D',
			},
			labels: {
				show: false,
				style: {
					colors: '#40475D',
					fontSize: '14px',
				},
				formatter: function (val, index) {
					return val?.toLocaleString('pt-BR', {
						maximumFractionDigits: 2,
					});
				},
			},
		},
		yaxis: {
			reversed: false,
			opposite: false,
			floating: false,
			axisBorder: {
				show: false,
			},
			axisTicks: {
				show: false,
				color: 'white',
			},
			labels: {
				show: true,
				style: {
					colors: 'white',
					fontSize: '14px',
					fontStyle: 'bold',
				},
				formatter: function (val, index) {
					return val?.toLocaleString('pt-BR', {
						maximumFractionDigits: 2,
					});
				},
			},
		},
		tooltip: {
			theme: 'dark',
			y: {
				show: true,
				formatter: function (value) {
					var formattedValue = new Intl.NumberFormat(
						'pt-BR'
					).format(value.toFixed(2));
					return formattedValue;
				},
			},
		},

		fill: {
			type: 'gradient',
			gradient: {
				type: 'radial',
				shadeIntensity: 1,
				gradientToColors: ['#02FFA980', '#6078ea'],
				inverseColors: true,
				opacityFrom: 1,
				opacityTo: 1,
				stops: [],
				colorStops: [],
			},
		},
		legend: {
			show: true,
			showForSingleSeries: true,
			showForNullSeries: true,
			showForZeroSeries: true,
			position: 'top',
			horizontalAlign: 'left',
			fontStyle: 'bold',
			fontColor: 'white',
			fontSize: '16px',
			labels: {
				colors: 'white',
				useSeriesColors: false,
			},
			onItemClick: {
				toggleDataSeries: true,
			},
			onItemHover: {
				highlightDataSeries: true,
			},
		},
	};

	return (
		<div className="bg-transparent w-full h-full px-2 text-center min-h-[500px]" style={{ minHeight: `${Math.max(500, chartDataProps.ambientes.length * 50)}px` }}>
			<Chart
				options={options}
				series={series}
				type="bar"
				width="100%"
				height="100%"
			/>
		</div>
	);
};

export default ChartBarViewDefault;
