import React from 'react';
import { Link } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import apiUnidade from '../../services/apis/apiUnidade';
import apiEmpresa from '../../services/apis/apiEmpresa';

const MobileLinks = () => {
	const [autorizado, setAutorizado] = useState(false);
	const [userUnidade, setUserUnidade] = useState();
	const [userEmpresa, setUserEmpresa] = useState();

	const [isUsuarioBasico, setIsUsuarioBasico] = useState(false);
	const [unId, setUnId] = useState(null);

	const { verificarCargo, deslogar, userInfo } = useContext(AuthContext);

	useEffect(() => {
		verificarCargo('SUPER_USER', 'ADMIN').then((res) => {
			setAutorizado(res);
		});
		verificarCargo('BASIC_USER', 'ADMIN_UNIDADE').then((res) => {
			setIsUsuarioBasico(res);
		});
	}, [verificarCargo]);

	useEffect(() => {
		const unIdLocalStorage = localStorage.getItem('unId');
		if (unIdLocalStorage !== null) {
			apiUnidade.getById(unIdLocalStorage).then((res) => {
				setUserUnidade(res.data);
			});
		}
	}, [unId]);

	useEffect(() => {
		userUnidade !== undefined &&
			apiEmpresa.getById(userUnidade.empresaId).then((res) => {
				setUserEmpresa(res.data);
			});
	}, [userUnidade]);

	window.addEventListener('storage', () => {
		setUnId(localStorage.getItem('unId'));
	});

	return (
		<div className="flex w-full h-full flex-col mx-12">
			<div className="px-2 text-lg text-gray-900 dark:text-white text-center">
				<div className="font-bold">
					{userInfo.nome} {userInfo.sobrenome}
				</div>
			</div>
			<div className="flex flex-col text-lg text-gray-700 dark:text-gray-200 text-center w-full">
				<div className="flex flex-col px-2 text-gray-900 dark:text-white text-center">
					<div className="font-bold text-accent">
						Empresa:
					</div>
					<div className="uppercase">
						{userEmpresa?.nome || '-'}
					</div>
				</div>
				<div className="flex flex-col px-2 text-lg text-gray-900 dark:text-white text-center">
					<div className="font-bold text-accent">
						Unidade:
					</div>
					<div className="uppercase w-full">
						{userUnidade?.nome || '-'}
					</div>
				</div>
			</div>

			<ul className="py-2 text-lg text-gray-700 dark:text-gray-200 text-center">
				<li className="py-1">
					<Link to="/">
						<div className="block p-2 bg-primary hover:bg-accent text-white transition-all duration-200 rounded-md hover:text-darkaccent">
							Home
						</div>
					</Link>
				</li>
				<li className="py-1">
					<Link to="/suporte">
						<div className="block p-2 bg-primary hover:bg-accent text-white transition-all duration-200 rounded-md hover:text-darkaccent">
							Suporte
						</div>
					</Link>
				</li>
				{!isUsuarioBasico && (
					<li className="py-1">
						<Link to="/escolha-unidade">
							<div className="block p-2 bg-primary hover:bg-accent text-white transition-all duration-200 rounded-md hover:text-darkaccent">
								Alterar Empresa
							</div>
						</Link>
					</li>
				)}
				{autorizado && (
					<li className="py-1">
						<Link to="/admin/grupo/1">
							<div className="block p-2 bg-primary hover:bg-accent text-white transition-all duration-200 rounded-md hover:text-darkaccent">
								Admin
							</div>
						</Link>
					</li>
				)}
				<li className="py-1">
					<button
						onClick={() => deslogar()}
						className="block p-2 w-full bg-darkaccent text-white border-2 border-accent rounded-md hover:bg-accent hover:text-darkaccent hover:border-darkaccent transition-all duration-200"
					>
						Sign out
					</button>
				</li>
			</ul>
		</div>
	);
};

export default MobileLinks;
