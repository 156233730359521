import { Popover, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import CardLayout from '../common/CardLayout';
import TitleCard from '../common/TitleCard';
import Table from '../common/table';
import NoEquipAlert from '../common/returns/NoEquipAlert';
import InputGeneric from '../common/InputGeneric';
import { ToastContext } from '../../contexts/ToastContext';
import apiAmbientes from '../../services/apis/apiAmbientes';
import apiRegimes from '../../services/apis/apiRegimes';
import EditIcon from '../common/EditIcon';
import { Button } from '../ui/button';
import { LiaTemperatureLowSolid } from 'react-icons/lia';
import NaoSeAplica from '../common/returns/NaoSeAplica';

import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '../../components/ui/accordion';

const ListAmbientes = ({ unidadeID }) => {
	const { verificarCargo } = useContext(AuthContext);
	const [ambientes, setAmbientes] = useState([]);
	const [ambientesData, setAmbientesData] = useState([]);
	const [autorizado, setAutorizado] = useState(false);
	const [regimes, setRegimes] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [refTemp, setRefTemp] = useState(0);
	const [processoH, setProcessoH] = useState(0);
	const [produtoKgUn, setProdutoKgUn] = useState(0);
	const { erro, sucesso } = useContext(ToastContext);

	const fetchData = useCallback(async () => {
		try {
			if (!unidadeID) return;
			const res = await apiRegimes.getByUnidadeId(unidadeID);

			setRegimes(
				res.data.filter(
					(regime) => regime.tipoRegime !== 'Condensação'
				)
			);

			const ambientesData = (await apiAmbientes.getByUnidadeId(unidadeID)).data;

			setAmbientes(ambientesData);

		} catch (error) {
			console.error(
				`Erro fetch ambientes [Sobre Unidade - ListAmbientes]: ${error.message}`
			);
		}
	}, [unidadeID]);

	useEffect(() => {
		fetchData();
	}, [unidadeID, fetchData]);

	useEffect(() => {
		const getNameById = (id) => {
			const regime = regimes.find((regime) => regime.id === id);
			if (regime) {
				return regime.nome;
			}

			return '';
		};
		if (ambientes) {
			const updateAmbienteData = ambientes.map((ambiente) => {
				return {
					...ambiente,
					regime: getNameById(ambiente.regimeId),
				};
			});
			setAmbientesData(updateAmbienteData);
		}
	}, [ambientes, regimes]);

	useEffect(() => {
		verificarCargo('SUPER_USER', 'ADMIN').then((res) => {
			setAutorizado(res);
		});
	}, [verificarCargo]);


	const handlePutAmbiente = (ambiente) => {
		setIsLoading(true);
		/*  <> _REFAROTAÇÃO_ <>
             @ViniciusMarchand @ViniciusMarchandCESS modificar backend para caso não receba os valores no put, manter os valores antigos. 
           Após essa correção, tem campos que podem ser editados vide ENUM, então é necessário correção no CADASTRO DE ADMIN DOS AMBIENTES. 10.07.2024
           */
		apiAmbientes
			.putApi(ambiente.id, {
				/* Valores antigos */
				nome: ambiente.nome,
				tipoAmbiente: ambiente.tipoAmbiente,
				largura: ambiente.largura,
				altura: ambiente.altura,
				comprimento: ambiente.comprimento,
				fatorAmbiente: ambiente.fatorAmbiente,
				regimeId: ambiente.regimeId,
				/* Valores editados */
				massaProduto: parseFloat(produtoKgUn),
				tempoProcesso: parseFloat(processoH),
				temperaturaReferencia: parseFloat(refTemp),
			})
			.then(
				(res) =>
					res.status === 200 &&
					sucesso(
						`Ambiente ${ambiente?.nome} editado com sucesso! `
					)
			)
			.catch((error) => {
				erro(
					'Não foi possível alterar os dados do ambiente, verifique se os dados foram preenchidos corretamente.'
				);
			})
			.finally(() => {
				setIsLoading(false);
				fetchData();
			});
	};

	return (
		<CardLayout>
			<Accordion
				type="single"
				defaultValue="item-1"
				collapsible="true"
			>
				<AccordionItem value="item-1">
					<div className="font-semibold">
						<AccordionTrigger>
							<TitleCard>
								Ambientes
								<p className="text-lg lg:text-4xl px-2">
									<LiaTemperatureLowSolid />
								</p>
							</TitleCard>
						</AccordionTrigger>
					</div>
					<AccordionContent>
						<div className="w-full">
							{ambientesData.length > 0 && (
								<Table.Root>
									<Table.Head>
										{/* <Table.Header>
											<p className="font-bold">
												. . .
											</p>
										</Table.Header> */}
										<Table.Header>
											Nome
										</Table.Header>
										<Table.Header>
											Temperatura
										</Table.Header>
										<Table.Header>
											Regime
										</Table.Header>
										<Table.Header>
											Setor
										</Table.Header>
										<Table.Header>
											AREA
											<p className="text-xs px-[2px] uppercase inline">
												(L*C*A)
											</p>
										</Table.Header>
										<Table.Header>
											Referência
										</Table.Header>
										<Table.Header>
											Processo
										</Table.Header>
										<Table.Header>
											Produto
										</Table.Header>
										<Table.Header>
											Carga Termica
										</Table.Header>
									</Table.Head>
									<Table.Body>
										{ambientesData?.map(
											(
												ambiente
											) => (
												<Table.Row
													key={
														ambiente.id
													}
												>
													{/* <Table.Data>
														<Popover
															as="div"
															className="relative z-10"
														>
															{autorizado ? (
																<Popover.Button
																	className="flex outline-none pl-2"
																	onClick={() =>
																		setIsOpen(
																			!isOpen
																		)
																	}
																>
																	<EditIcon
																		status={
																			autorizado
																				? 'unlock'
																				: 'lock'
																		}
																	/>
																</Popover.Button>
															) : (
																<EditIcon
																	status={
																		autorizado
																			? 'unlock'
																			: 'lock'
																	}
																/>
															)}
															{/* Pop over body para editar ambiente */}
															{/* <Transition
																as={
																	Fragment
																}
																enter="transition ease-in duration-200"
																enterFrom="opacity-0 translate-y-20"
																enterTo="opacity-100 translate-y-0"
																leave="transition ease-in duration-150"
																leaveFrom="opacity-100 -translate-y-10"
																leaveTo="opacity-0 translate-y-20"
															>
																<Popover.Panel className="bg-darkaccent border shadow-2xl rounded-md border-glassColor absolute z-10 md:translate-x-[10%] translate-x-[10%] py-1 px-auto -bottom-[0.9rem] w-[22rem] lg:w-[40rem]">
																	<div className="text-white gap-x-1 flex flex-col lg:flex-row w-full text-xs">
																		<div className="flex justify-center w-full p-2 gap-x-4">
																			<div className="flex flex-col lg:text-[1rem] ">
																				<div className="flex justify-center pb-[1px]">
																					<label
																						name="refTemAmbiente"
																						className="text-secondary"
																					>
																						REFERÊNCIA
																						(°C)
																					</label>
																				</div>
																				<div className="flex lg:px-4 max-w-[6rem] lg:max-w-36 mx-auto">
																					<InputGeneric
																						value={
																							refTemp
																						}
																						onChange={(
																							event
																						) =>
																							setRefTemp(
																								event
																									.target
																									.value
																							)
																						}
																						id="refTemAmbiente"
																						name="refTemAmbiente"
																					/>
																				</div>
																			</div>
																			{ambiente.tipoAmbiente ===
																				'Túnel_congelamento' && (
																				<div className="flex flex-col lg:text-[1rem]">
																					<div className="flex justify-center pb-[1px]">
																						<label
																							name="tempProcessoAmbiente"
																							className="text-secondary"
																						>
																							PROCESSO
																							(H)
																						</label>
																					</div>
																					<div className="flex lg:px-4 max-w-[6rem] lg:max-w-36 mx-auto">
																						<InputGeneric
																							value={
																								processoH
																							}
																							onChange={(
																								event
																							) =>
																								setProcessoH(
																									event
																										.target
																										.value
																								)
																							}
																							id="tempProcessoAmbiente"
																							name="tempProcessoAmbiente"
																						/>
																					</div>
																				</div>
																			)}
																			{ambiente.tipoAmbiente ===
																				'Túnel_congelamento' ||
																			ambiente.tipoAmbiente ===
																				'Câmara_resfriamento' ? (
																				<div className="flex flex-col lg:text-[1rem]">
																					<div className="flex justify-center pb-[1px]">
																						<label
																							name="prodKgUnAmbiente"
																							className="text-secondary"
																						>
																							PRODUTO
																							(Kg/Un)
																						</label>
																					</div>
																					<div className="flex lg:px-4 max-w-[6rem] lg:max-w-36 mx-auto">
																						<InputGeneric
																							value={
																								produtoKgUn
																							}
																							onChange={(
																								event
																							) =>
																								setProdutoKgUn(
																									event
																										.target
																										.value
																								)
																							}
																							id="prodKgUnAmbiente"
																							name="prodKgUnAmbiente"
																						/>
																					</div>
																				</div>
																			) : null}
																		</div>
																		<div className="flex lg:text-lg w-60 lg:px-4 lg:my-1 mx-auto">
																			<Button
																				onClick={() => {
																					handlePutAmbiente(
																						ambiente
																					);
																				}}
																				loading={
																					isLoading
																				}
																				className="w-full lg:h-8 h-[1.8rem] mt-auto mb-1"
																			>
																				Salvar
																			</Button>
																		</div>
																	</div>
																</Popover.Panel>
															</Transition>
														</Popover> */}
													{/* </Table.Data>  */}
													<Table.Data className="px-1 text-[1rem] font-semibold text-accent">
														{ambiente?.nome ||
															'-'}
													</Table.Data>
													<Table.Data className="px-auto">
														{ambiente
															.leiturasAmbientes[0]
															?.dataHora ? (
															<>
																<InputGeneric
																	className="max-w-[88px] mx-auto "
																	value={
																		ambiente.leiturasAmbientes[0].temperaturaAtual.toLocaleString(
																			'pt-BR',
																			{
																				maximumFractionDigits: 2,
																			}
																		) +
																		' °C'
																	}
																	type="string"
																	id={
																		'temperatura' +
																		ambiente.id
																	}
																	name={
																		'temperatura' +
																		ambiente.id
																	}
																	readOnly
																/>
															</>
														) : (
															'-'
														)}
													</Table.Data>
													<Table.Data>
														{ambiente?.regime ||
															'-'}
													</Table.Data>
													<Table.Data>
														{ambiente?.tipoAmbiente.replaceAll(
															'_',
															' '
														) ||
															'-'}
													</Table.Data>
													<Table.Data>
														{ambiente.tipoAmbiente ===
															'Túnel_congelamento' ||
														ambiente.tipoAmbiente ===
															'Câmara_resfriamento' ? (
															<NaoSeAplica />
														) : (
															<>
																{ambiente?.largura +
																	' * ' ||
																	'- *'}
																{ambiente?.comprimento +
																	' * ' ||
																	'- *'}
																{ambiente?.altura ||
																	'-'}{' '}
															</>
														)}
													</Table.Data>
													<Table.Data>
														{ambiente?.temperaturaReferencia ? (
															<InputGeneric
																className="max-w-[88px] mx-auto"
																readOnly={
																	true
																}
																type="string"
																value={
																	ambiente.temperaturaReferencia.toLocaleString(
																		'pt-BR',
																		{
																			maximumFractionDigits: 2,
																		}
																	) +
																	' °C'
																}
															/>
														) : (
															'-'
														)}
													</Table.Data>
													<Table.Data>
														{ambiente.tipoAmbiente ===
															'Túnel_congelamento' ||
														ambiente.tipoAmbiente ===
															'Câmara_resfriamento' ? (
															<InputGeneric
																className="max-w-[88px] mx-auto"
																readOnly={
																	true
																}
																value={
																	ambiente.tempoProcesso.toLocaleString(
																		'pt-BR',
																		{
																			maximumFractionDigits: 2,
																		}
																	) +
																	' Horas'
																}
																type="string"
																id={
																	'tempoProcesso' +
																	ambiente.id
																}
																name={
																	'tempoProcesso' +
																	ambiente.id
																}
															/>
														) : (
															<NaoSeAplica />
														)}
													</Table.Data>
													<Table.Data>
														{ambiente.tipoAmbiente ===
														'Túnel_congelamento' ? (
															<InputGeneric
																className="max-w-[88px] mx-auto"
																readOnly={
																	true
																}
																type="string"
																value={
																	ambiente.massaProduto.toLocaleString(
																		'pt-BR',
																		{
																			maximumFractionDigits: 2,
																		}
																	) +
																	' kg/un'
																}
															/>
														) : (
															<NaoSeAplica />
														)}
													</Table.Data>
													<Table.Data className="font-semibold">
														{ambiente?.cargaTermica
															? ambiente.cargaTermica.toLocaleString(
																	'pt-BR',
																	{
																		maximumFractionDigits: 2,
																	}
															  ) +
															  ' Kcal/ h'
															: ' - Kcal/ h'}
													</Table.Data>
												</Table.Row>
											)
										)}
									</Table.Body>
								</Table.Root>
							)}
							{ambientes.length === 0 && (
								<NoEquipAlert equipments="regimes" />
							)}
						</div>
					</AccordionContent>
				</AccordionItem>
			</Accordion>
		</CardLayout>
	);
};

export default ListAmbientes;
