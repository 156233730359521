import React, { useEffect, useState } from 'react';
import SmallCardLayout from '../../common/SmallCardLayout';
import { LiaTemperatureLowSolid } from 'react-icons/lia';
import apiAmbientes from '../../../services/apis/apiAmbientes';
import SignalService from '../../../services/SignalService';
import { apiLink } from '../../../constants/env';
import getCurrentUnidadeId from '../../../funcs/getCurrentUnidadeId';

const ListAmbientes = ({ listaRegimes }) => {
	const [listaAmbientes, setListaAmbientes] = useState([]);
	const [listaTemperaturas, setListaTemperaturas] = useState([]);
	const [hubConnection, setHubConnection] = useState();
	const [dadoAtualizado, setDadoAtualizado] = useState();
	const unidadeId = getCurrentUnidadeId();

	//GET AMBIENTES
	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await apiAmbientes.getByUnidadeId(unidadeId);
				const ambientes = res.data;
				
				const newAmbientes = ambientes.map((ambiente) => {
					const regimePertencente = listaRegimes.find((regime) => regime.id === ambiente.regimeId);

					ambiente['borderClass'] = regimePertencente['RegimeColor'];
					return ambiente;
				});
				
				setListaAmbientes(newAmbientes);
				
			} catch (error) {
				
			}
		};
		fetchData();
	}, [listaRegimes, unidadeId]);
	
	useEffect(() => {
		const leituras = listaAmbientes.map(ambiente => {
			const leitura  = ambiente.leiturasAmbientes[0];

			if (leitura) {
				return leitura;
			}

			return {
				temperaturaAtual: '-',
				ambienteId: ambiente.id
			}
		});

		setListaTemperaturas(leituras);

	}, [listaAmbientes]);

	//ATUALIZAÇÃO EM TEMPO REAL
	useEffect(() => {
		setHubConnection(
			SignalService.startConnection(`${apiLink}LeituraAmbienteHub`, unidadeId)
		);
	}, [unidadeId]);


	useEffect(() => {
		if (hubConnection) {
			hubConnection
				.start()
				.then(() => {
					return hubConnection.invoke('Connect', unidadeId);
				})
				.then((result) => {
					hubConnection.on(
						'AtualizarDadosAmbiente',
						(message) => {
							setDadoAtualizado(message);
						}
					);
				})
				.catch((e) => console.log('Conexão falhou. ', e));
		}
	}, [hubConnection, unidadeId]);

	//ATUALIZA DADOS
	useEffect(() => {
		if (dadoAtualizado) {
				const index = listaTemperaturas.findIndex(
					(elemento) => {
						return (
							elemento.ambienteId ===
							dadoAtualizado.ambienteId
						);
					}
				);

				let arrayAux = [...listaTemperaturas];
				if(index === -1) {
					arrayAux.push(dadoAtualizado);
				} else {
					arrayAux[index] = dadoAtualizado;	
				}
				setListaTemperaturas([...arrayAux]);
				setDadoAtualizado(null);
		}
	}, [dadoAtualizado, listaTemperaturas]);

	function getTemperaturaByAmbienteId(id) {
		const temperatura = listaTemperaturas.find(
			(elemento) => elemento.ambienteId === id
		);
		return temperatura?.temperaturaAtual;
	}

	function getReference(id) {
		const ref = listaAmbientes.flat().find((obj) => obj.id === id);
		return ref?.temperaturaReferencia;
	}

	function formatTemperatura(id) {
		const temp = getTemperaturaByAmbienteId(id);
		const ref = getReference(id);

		let value = temp.toLocaleString('pt-BR', {
			maximumFractionDigits: 2,
		});

		let classTemp = '';

		if (temp > ref) {
			classTemp = 'text-red-500 animate-pulse';
		} else if (temp < ref - 5) {
			classTemp = 'text-yellow-300 animate-pulse';
		} else {
			classTemp = 'text-white';
		}

		return { value, classTemp };
	}

	return (
		<>
			{listaAmbientes.length > 0 ? (
				<div className="flex flex-col w-full mt-2">
					<div className="flex flex-col h-full text-white">
						<div className="flex flex-wrap  text-center h-full w-full mb-3">
							<SmallCardLayout className="flex items-center border-glassColor h-full justify-center w-full gap-x-3">
								<>
									<p>A</p>
									<p>M</p>
									<p>B</p>
									<p>I</p>
									<p>E</p>
									<p>N</p>
									<p>T</p>
									<p>E</p>
									<p>S</p>
								</>
							</SmallCardLayout>
						</div>
					</div>
					<div className="flex flex-wrap w-full h-full gap-y-2 gap-x-4">
						{listaAmbientes.filter(({monitorado}) => monitorado === true).map((ambiente, i) => (
							<div
								className={
									listaAmbientes.length <= 3
										? 'w-full'
										: 'w-full min-w-[22rem] flex-1'
								}
								key={i}
							>
								<SmallCardLayout
									className={`${ambiente.borderClass} h-full `}
								>
									<div className="flex flex-col flex-grow items-center w-full h-full justify-center">
										<div className="flex items-center h-full text-secondary drop-shadow-1xl text-lg text-center">
											{
												ambiente.nome
											}{' '}
										</div>
										<div className="flex items-center h-full">
											<LiaTemperatureLowSolid className="text-accent/40 w-6 h-6 mr-3" />
											{!isNaN(getTemperaturaByAmbienteId(
												ambiente.id
											)) ? (
												<span
													className={`font-bold drop-shadow-1xl text-lg 2xl:text-3xl ${
														formatTemperatura(
															ambiente.id
														)
															.classTemp
													}`}
												>
													{
														formatTemperatura(
															ambiente.id
														)
															.value
													}
													<p className="text-secondary inline-flex px-3 text-sm lg:text-lg">
														°C
													</p>
												</span>
											) : (
												<span className="animate-pulse text-alarm/60 font-semibold drop-shadow-1xl text-lg">
													OFF
												</span>
											)}
										</div>
									</div>
								</SmallCardLayout>
							</div>
						))}
					</div>
				</div>
			) : (
				<div className="-top-4"></div>
			)}
		</>
	);
};

export default ListAmbientes;
