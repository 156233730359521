import { apiLink } from '../../constants/env';
import axiosInstance from '../AxiosInstance';

const apiCompressores = {
    fetchApi: async (page) => {
        const URL = `${apiLink}api/compressor?page=` + page;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
            })
    },
    postApi: async (compressor) => {
        const URL = `${apiLink}api/compressor`;
        return await axiosInstance.post(URL, compressor)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao fazer o post dos dados:', error);
                throw new Error(error);
            })
    },
    putApi: async (id, compressor) => {
        const URL = `${apiLink}api/compressor/${id}`;
        return await axiosInstance.put(URL, compressor)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao fazer o post dos dados:', error);
                throw new Error();

            })
    },
    deleteApi: async (id) => {
        const URL = `${apiLink}api/compressor/status/${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao deletar o Compressor:', error);
                throw new Error();

            })
    },
    getByRegimeId: async (id) => {
        const URL = `${apiLink}api/compressor/regime/${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao pegar Compressor:', error);
                throw new Error();

            })
    },

    getByUnidadeId: async (id) => {
        const URL = `${apiLink}api/compressor/unidade/${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                throw new Error(error.message);
            })
    },

}

export default apiCompressores; 