import React, { useState, useEffect, useCallback } from 'react';
import Table from '../../common/table';
import NoEquipAlert from '../../common/returns/NoEquipAlert';
import StatusOnOff from '../../common/StatusOnOff';
import InputGeneric from '../../common/InputGeneric';

import apiVentiladores from '../../../services/apis/apiVentilador';
import apiCondensador from '../../../services/apis/apiCondensadores';

const TableVentiladores = ({ unidadeId, regimes, condensacao }) => {
	const [ventiladoresData, setVentiladoresData] = useState([]);

	const fetchData = useCallback(async () => {
		if (!unidadeId && !condensacao && !regimes) return;
		try {
			const ventiladores = (await apiVentiladores.getByUnidadeIdV2(unidadeId)).data;

			if (ventiladores.length > 0) {

				const condensadorVentiladores = ventiladores.filter(
					(ventilador) =>
						ventilador.tipoEquipamento === 'Condensador'
				);
				
				const condensadores = (await apiCondensador.getByUnidadeId(unidadeId)).data;

				const ventiladorData = condensadorVentiladores.map(
					(ventilador) => {

						return {
							...ventilador,
							condensador: condensadores.find(
								(condensador) => condensador.id === ventilador.condensadorId
							),

						};
					}
				);

				ventiladorData.sort((a, b) => {
					if (a.condensador.nome < b.condensador.nome) {
						return -1;
					}
					if (a.condensador.nome > b.condensador.nome) {
						return 1;
					}
					return 0;
				});

				setVentiladoresData(ventiladorData);
			}
		} catch (error) {
			console.log(
				`Erro fetching ventiladores [SobreUnidade - Ventiladores]: ${error}`
			);
		}
	}, [unidadeId, condensacao, regimes]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);


	return (
		<div className="w-full">
			{ventiladoresData.length > 0 ? (
				<Table.Root>
					<Table.Head>
						<Table.Header>Nome</Table.Header>
						{/* <Table.Header>Regime</Table.Header> */}
						<Table.Header>Equipamento</Table.Header>
						<Table.Header>Marca</Table.Header>
						<Table.Header>Modelo</Table.Header>
						<Table.Header>Potência</Table.Header>
					</Table.Head>

					<Table.Body>
						{ventiladoresData.map((ventilador) => (
							<Table.Row key={ventilador.id}>
								<Table.Data>
									{ventilador.nome}
								</Table.Data>
								{/* <Table.Data>
									{ventilador.regime}
								</Table.Data> */}
								<Table.Data>
									{ventilador.condensador.nome}
								</Table.Data>
								<Table.Data>
									{ventilador?.modelo?.marca?.nome || 'Sem Marca'}
								</Table.Data>
								<Table.Data>
									{ventilador?.modelo?.nome || 'Sem Modelo'}
								</Table.Data>
								<Table.Data>
									{ventilador.potenciaNominal +
										' CV'}
								</Table.Data>
							</Table.Row>
						))}
					</Table.Body>
				</Table.Root>
			) : (
				<NoEquipAlert equipments="ventiladores" />
			)}
		</div>
	);
};

export default TableVentiladores;
