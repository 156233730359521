import { apiLink } from '../../../constants/env';
import axiosInstance from '../../AxiosInstance';

const apiLeituraLinhaLiquido = {
    getUltimaLeitura: async (id) => {
        const URL = `${apiLink}api/leitura-linha-liquido/ultima-leitura?unidadeId=${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                throw new Error(error);
            })
    },
}

export default apiLeituraLinhaLiquido;
