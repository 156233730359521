import { useEffect, useState } from "react";
import CardLayout from "../common/CardLayout";
import NoDataAlert from "../common/returns/NoDataAlert";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../ui/accordion";
import { GaugeChart } from "./GaugeChart";

export default function Condensacao({dadosCondensacao}) {
    const [condensacao, setCondensacao] = useState();
    const [tbu, setTbu] = useState();
    const [linhaLiquido, setLinhaLiquido] = useState();
        
    useEffect(() => {
        const novaCondensacao = dadosCondensacao?.condensacaoInfo?.condensacao || 0;
        const condensacaoComLeituras = {
            ...novaCondensacao,
            leiturasRegimes: dadosCondensacao?.condensacaoInfo?.leituraCondensacao
        }

        setCondensacao(condensacaoComLeituras);
        setTbu(dadosCondensacao?.ultimaLeituraTbu);
        setLinhaLiquido(dadosCondensacao?.ultimaLeituraLinhaLiquido || 0);


    }, [dadosCondensacao]);

    return (
        !condensacao ? <NoDataAlert /> :  
        <CardLayout>
            <Accordion
                type="single"
                defaultValue={`item-condensacao`}
                collapsible={true}
            >
                <AccordionItem
                    value={`item-condensacao`}
                >
                    <div className="font-semibold">
                        <AccordionTrigger>
                            <div className="w-full flex items-center justify-center text-sm lg:text-2xl drop-shadow-1xl">
                                    <div
                                        className={`uppercase`}
                                    >
                                        <p>
                                            <span className="capitalize">
                                                {
                                                    condensacao.nome
                                                }
                                            </span>
                                        </p>
                                    </div>
                            </div>
                        </AccordionTrigger>
                    </div>
                    {
                        !condensacao.leiturasRegimes ?
                            <NoDataAlert /> :
                            <AccordionContent>
                                <div className="flex flex-col gap-2 px-4 items-center justify-center">
                                    <div className="flex flex-wrap justify-center">
                                        <div>
                                            <GaugeChart valor={condensacao.leiturasRegimes.eficienciaCondensadores} label={"Eficiência dos condensadores"} />
                                        </div>
                                        <div className="flex flex-col min-h-full justify-around items-center">
                                            <div className="flex text-center justify-center border-2 items-center border-accent rounded-full h-8 w-full max-w-[20rem] 3xl:px-4 my-2">
                                                <p className="text-sm 3xl:text-lg font-bold px-4 text-accent">
                                                    Líquido:
                                                </p>
                                                <span className="font-bold drop-shadow-1xl text-white px-4 md:px-2 text-sm 3xl:text-lg">
                                                    {linhaLiquido ? linhaLiquido.temperatura?.toLocaleString(
                                                        'pt-BR',
                                                        {
                                                            maximumFractionDigits: 2,
                                                        }
                                                    ) + " ºC"
                                                    : "Sem dados"
                                                    }
                                                </span>
                                            </div>
                                            <div className="flex text-center justify-center border-2 items-center border-accent rounded-full h-8 w-full max-w-[20rem] 3xl:px-4 my-2">
                                                <p className="text-sm 3xl:text-lg font-bold px-4 text-accent">
                                                    TBU:
                                                </p>
                                                <span className="font-bold drop-shadow-1xl text-white px-4 md:px-2 text-sm 3xl:text-lg">
                                                    {tbu ? tbu.tbuCalculado?.toLocaleString(
                                                        'pt-BR',
                                                        {
                                                            maximumFractionDigits: 2,
                                                        }
                                                    ) ||  + " ºC"
                                                    : "Sem dados"
                                                }
                                                </span>
                                            </div>
                                            <div className="flex text-center justify-center border-2 items-center border-accent rounded-full h-8 w-full max-w-[20rem] 3xl:px-4 my-2">
                                                <p className="text-sm 3xl:text-lg font-bold px-4 text-accent">
                                                    Incondensáveis:
                                                </p>
                                                <span className="font-bold drop-shadow-1xl text-white px-4 md:px-2 text-sm 3xl:text-lg">
                                                    {condensacao.leiturasRegimes?.incondensaveisBar?.toLocaleString(
                                                        'pt-BR',
                                                        {
                                                            maximumFractionDigits: 2,
                                                        }
                                                    ) + " bar"}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex text-center justify-center border-2 items-center border-red-600 rounded-full h-8 w-[550px] 3xl:px-4 my-2">
                                        <p className="text-sm 3xl:text-lg font-bold px-4 text-red-600">
                                            Desperdício mensal de energia:
                                        </p>
                                        <span className="font-bold drop-shadow-1xl text-white px-4 md:px-2 text-sm 3xl:text-lg">
                                            {condensacao.leiturasRegimes?.desperdicio?.toLocaleString(
                                                'pt-BR',
                                                {
                                                    maximumFractionDigits: 2,
                                                }
                                            ) + " kWh"}
                                        </span>
                                    </div>
                                </div>
                            </AccordionContent>
                    }
                </AccordionItem>
            </Accordion>
        </CardLayout>
    );
}