import React, { useState, useEffect, useCallback } from 'react';
import Table from '../../common/table';
import NoEquipAlert from '../../common/returns/NoEquipAlert';

import apiMaquinaGelo from '../../../services/apis/apiMaquinaGelo';
import getCurrentUnidadeId from '../../../funcs/getCurrentUnidadeId';

const TableMaquinasGelo = ({ regimes }) => {
	const [maquinasGeloData, setMaquinasGeloData] = useState([]);

	const fetchData = useCallback(async () => {
		try {
			const unidadeId = getCurrentUnidadeId();
			if (!regimes) return;
			
			const maquinasGelo = (await apiMaquinaGelo.getByUnidadeId(unidadeId)).data;

			const maquinasGeloData = maquinasGelo.map(
				(maquinaGelo) => {
					const regimeName = regimes.find(
						(regime) =>
							regime.id === maquinaGelo.regimeId
					).nome;

					return {
						...maquinaGelo,
						regime: regimeName,
					};
				}
			);

			setMaquinasGeloData(maquinasGeloData);

		} catch (error) {
			console.error(
				`Error fetching maquinas de gelo [SobreUnidade]: ${error}`
			);
		}
	}, [regimes]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	return (
		<div className="w-full">
			{maquinasGeloData.length > 0 ? (
				<Table.Root>
					<Table.Head>
						<Table.Header>Nome</Table.Header>
						<Table.Header>Regime</Table.Header>
						<Table.Header>Marca</Table.Header>
						<Table.Header>Modelo</Table.Header>
						<Table.Header>
							Capacidade
							{/* <p className="lowercase px-[1px] text-xs inline">
								(ton/dia)
							</p> */}
						</Table.Header>
						<Table.Header>MASSA DE GELO</Table.Header>
						<Table.Header>
							DEGELO
							{/* <p className="lowercase px-[1px] text-xs inline">
								(min)
							</p> */}
						</Table.Header>
						<Table.Header>
							PRODUÇÃO
							{/* <p className="lowercase px-[1px] text-xs inline">
								(min)
							</p> */}
						</Table.Header>
						<Table.Header>Potencia</Table.Header>
						<Table.Header>Carga Termica</Table.Header>
					</Table.Head>

					<Table.Body>
						{maquinasGeloData.map((maquinaGelo) => (
							<Table.Row key={maquinaGelo.id}>
								<Table.Data>
									{maquinaGelo.nome}
								</Table.Data>
								<Table.Data>
									{maquinaGelo.regime}
								</Table.Data>
								<Table.Data>
									{maquinaGelo.modelo.marca.nome}
								</Table.Data>
								<Table.Data>
									{maquinaGelo.modelo.nome}
								</Table.Data>
								<Table.Data>
									{maquinaGelo.capacidadeProducao.toLocaleString(
										'pt-BR',
										{
											maximumFractionDigits: 2,
										}
									) + ' Ton/Dia'}
								</Table.Data>
								<Table.Data>
									{maquinaGelo.massaGeloHora.toLocaleString(
										'pt-BR',
										{
											maximumFractionDigits: 2,
										}
									) + ' Ton/ h'}
								</Table.Data>
								<Table.Data>
									{
										maquinaGelo.tempoProducao
									}
									{' min'}
								</Table.Data>
								<Table.Data>
									{maquinaGelo.tempoDegelo +
										' min'}
								</Table.Data>
								<Table.Data>
									{maquinaGelo.potencia.toLocaleString(
										'pt-BR',
										{
											maximumFractionDigits: 2,
										}
									) + ' CV'}
								</Table.Data>
								<Table.Data>
									{maquinaGelo.cargaTermica.toLocaleString(
										'pt-BR',
										{
											maximumFractionDigits: 2,
										}
									) + ' Kcal/ h'}
								</Table.Data>
							</Table.Row>
						))}
					</Table.Body>
				</Table.Root>
			) : (
				<NoEquipAlert equipments="maquinas de gelo" />
			)}
		</div>
	);
};

export default TableMaquinasGelo;
