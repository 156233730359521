import Chart from 'react-apexcharts';

const ChartEECondensadores2 = ({ chartDataProps }) => {

	const ieeReal = chartDataProps.map((data) => data.leiturasCondensadores[0].ieeReal || 0);
	const ieeNominal = chartDataProps.map(
		(data) => data.leiturasCondensadores[0].ieeNominal || 0
	);
	

	const qtd = chartDataProps.map((data) => data.nome).length;
	let tamGrafico, tamFont;

	if (qtd <= 10) {
		tamGrafico = 500;
		tamFont = '15px';
	} else if (qtd <= 20) {
		tamGrafico = 650;
	} else if (qtd <= 30) {
		tamGrafico = 800;
	} else {
		tamGrafico = 1000;
		tamFont = '14px';
	}

	const options = {
		chart: {
			id: 'basic-bar',
			toolbar: {
				show: false,
			},
			animations: {
				enabled: false 
			}
		},
		grid: {
			show: false,
			borderColor: '#40475D',
			strokeDashArray: 0,
		},
		plotOptions: {
			bar: {
				borderRadius: 3,
				borderRadiusApplication: 'end',
				borderRadiusWhenStacked: 'last',
				columnWidth: '100%',
				horizontal: true,
				dataLabels: {
					position: 'center',
				},
			},
		},
		dataLabels: {
			enabled: true,
			textAnchor: 'end', //
			style: {
				colors: ['#FFF'],
				fontSize: tamFont,
			},
			offsetY: 0,
			offsetX: 0,
			dropShadow: {
				enabled: true,
				left: 2,
				top: 2,
				opacity: 0.5,
			},
			formatter: function (val, index) {
				return val.toLocaleString('pt-BR', {
					maximumFractionDigits: 2,
				});
			},
		},
		stroke: {
			width: 1,
			colors: ['#33333380'],
		},
		xaxis: {
			categories: chartDataProps.map((data) => data.nome),
			position: 'bottom',
			axisBorder: {
				show: false,
			},
			axisTicks: {
				show: false,
				color: '#40475D',
			},
			labels: {
				show: false,
				style: {
					colors: 'white',
					fontSize: '18px',
				},
				formatter: function (val, index) {
					return val.toLocaleString('pt-BR', {
						maximumFractionDigits: 2,
					});
				},
			},
		},
		yaxis: {
			reversed: true,
			opposite: true,
			floating: false,
			axisBorder: {
				show: false,
			},
			axisTicks: {
				show: false,
				color: '#40475D',
			},
			labels: {
				show: true,
				style: {
					colors: 'white',
					fontSize: '20px',
					fontStyle: 'bold',
				},
				formatter: function (val, index) {
					return val.toLocaleString('pt-BR', {
						maximumFractionDigits: 2,
					});
				},
			},
		},
		tooltip: {
			theme: 'dark',
		},
		fill: {
			type: 'gradient',
			gradient: {
				type: 'radial',
				shadeIntensity: 1,
				gradientToColors: ['#6078ea', '#02FFA980'], // optional, if not defined - uses the shades of same color in series
				inverseColors: false,
				opacityFrom: 1,
				opacityTo: 1,
				stops: [],
				colorStops: [],
			},
		},
		legend: {
			show: true,
			showForNullSeries: true,
			showForZeroSeries: true,
			position: 'top',
			fontStyle: 'bold',
			fontColor: 'white',
			fontSize: '18px',
			labels: {
				colors: 'white',
				useSeriesColors: false,
			},
			onItemClick: {
				toggleDataSeries: true,
			},
			onItemHover: {
				highlightDataSeries: true,
			},
		},
	};

	const series = [
		{
			name: 'Indice de Eficiência Medido (Mcal/kwh)',
			data: ieeReal,
			color: '#0CABF5',
		},
		{
			name: 'Indice de Eficiência Nominal (Mcal/kwh)',
			data: ieeNominal,
			color: '#02FFA9',
		},
	];

	return (
		<div className="bg-transparent w-full h-full px-2">
			<Chart
				options={options}
				series={series}
				type="bar"
				width="100%"
				height={tamGrafico}
			/>
		</div>
	);
};

export default ChartEECondensadores2;
