import { apiLink } from '../../constants/env';
import axiosInstance from '../AxiosInstance';

const apiRegimes = {
    fetchApi: async (page) => {
        const URL = `${apiLink}api/regime?page=` + page;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
            })
    },
    fetchApiCondensacao: async (page) => {
        const URL = `${apiLink}api/condensacao?page=` + page;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
            })
    },
    getById: async (id) => {
        const URL = `${apiLink}api/regime/` + id;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
            })
    },
    postApi: async (regime) => {
        const URL = `${apiLink}api/regime`;
        return await axiosInstance.post(URL, regime)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao fazer o post dos dados:', error);
                throw new Error(error.message);

            })
    },
    postApiCondensacao: async (regime) => {
        const URL = `${apiLink}api/condensacao`;
        return await axiosInstance.post(URL, regime)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao fazer o post dos dados:', error);
                throw new Error(error.message);

            })
    },
    putApi: async (id, regime) => {
        const URL = `${apiLink}api/regime/${id}`;
        return await axiosInstance.put(URL, regime)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao fazer o post dos dados:', error);
                throw new Error(error.message);

            })
    },
    putApiCondensacao: async (id, regime) => {
        const URL = `${apiLink}api/condensacao/${id}`;
        return await axiosInstance.put(URL, regime)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao fazer o post dos dados:', error);
                throw new Error(error.message);

            })
    },
    deleteApi: async (id) => {
        const URL = `${apiLink}api/regime/status/${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao deletar o regime:', error);
            })
    },
    getByUnidadeId: async (id) => {
        const URL = `${apiLink}api/regime/unidade/${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao pegar regime:', error);
                throw new Error('Erro ao pegar regime:', error);
            })
    },
}

export default apiRegimes; 


