'use client';
import Chart from 'react-apexcharts';

const ChartViewLineVazoes = ({ condensador }) => {

	const leitura = condensador.leiturasCondensadores;

	const options = {
		chart: {
			id: 'basic-line',
			type: 'line',
			dropShadow: {
				enabled: true,
				color: '#123',
				top: 18,
				left: 7,
				blur: 10,
				opacity: 0.5,
			},
			toolbar: {
				show: false,
				tools: {
					download: false,
					selection: false,
					zoom: true,
					zoomin: true,
					zoomout: true,
					pan: false,
					reset: true,
				},
			},
			animations: {
				enabled: false,
				easing: 'easeinout',
				speed: 800,
				animateGradually: {
					enabled: true,
					delay: 150,
				},
				dynamicAnimation: {
					enabled: true,
					speed: 350,
				},
			},
		},
		grid: {
			show: false,
			borderColor: '#40475D',
			strokeDashArray: 0,
		},
		stroke: {
			show: true,
			curve: 'smooth',
			lineCap: 'butt',
			width: 1.2,
			dashArray: 0,
		},
		states: {
			hover: {
				filter: 'none',
			},
		},
		markers: {
			size: 0,
		},
		xaxis: {
			type: 'datetime',
			position: 'bottom',
			labels: {
				formatter: function (val) {
					const date = new Date(val);
					return date.toLocaleDateString('pt-BR', {
						month: '2-digit',
						day: '2-digit',
						hour: '2-digit',
						minute: '2-digit',
					});
				},
				show: true,
				style: {
					colors: 'rgba(12, 171, 245, 0.30)',
					fontSize: '12px',
					fontStyle: 'normal',
				},
			},
			axisTicks: {
				show: false,
				color: '#40475D',
			},
			axisBorder: {
				show: false,
			},
		},
		yaxis: {
			labels: {
				show: true,
				style: {
					colors: 'rgba(12, 171, 245, 0.30)',
					fontSize: '14px',
				},
				formatter: function (val, index) {
					return (
						val?.toLocaleString('pt-BR', {
							maximumFractionDigits: 2,
						}) + ' m³/s'
					);
				},
			},
			axisTicks: {
				show: false,
				color: '#40475D',
			},
			axisBorder: {
				show: false,
			},
		},
		tooltip: {
			theme: 'dark',
		},
		dataLabels: {
			enabled: false,
		},
		legend: {
			show: true,
			position: 'top',
			fontStyle: 'bold',
			fontColor: 'white',
			fontSize: '16px',
			labels: {
				colors: 'white',
				useSeriesColors: false,
			},
			onItemClick: {
				toggleDataSeries: true,
			},
			onItemHover: {
				highlightDataSeries: true,
			},
		},
	};

	const series =[ {
		name: 'Vazão',
		data: leitura.map((item) => {
			return {
				x: new Date(item.dataHora),
				y: item.vazaoVolumetricaAr,
			};
		}),
	}];
	
	if (!leitura) return null;

	return (
		<div className="bg-transparent w-full h-full p-2">
			<Chart
				options={options}
				series={series}
				width="100%"
				height={320}
			/>
		</div>
	);
};

export default ChartViewLineVazoes;
