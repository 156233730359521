import { apiLink } from '../../constants/env';
import axiosInstance from '../AxiosInstance';

const apiCondensadores = {
    fetchApi: async (page) => {
        const URL = `${apiLink}api/condensador?page=` + page;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    getById: async (id) => {
        const URL = `${apiLink}api/condensador/` + id;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    postApi: async (condensador) => {
        const URL = `${apiLink}api/condensador`;
        return await axiosInstance.post(URL, condensador)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao fazer o post dos dados:', error);
                throw new Error(error);
            })
    },
    putApi: async (id, condensador) => {
        const URL = `${apiLink}api/condensador/${id}`;
        return await axiosInstance.put(URL, condensador)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao fazer o post dos dados:', error);
                throw new Error();

            })
    },
    deleteApi: async (id) => {
        const URL = `${apiLink}api/condensador/status/${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao deletar o Condensador:', error);
                throw new Error();

            })
    },
    getByRegimeId: async (id) => {
        const URL = `${apiLink}api/condensador/regime/${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao pegar Condensadores:', error);
                throw new Error();

            })
    },
    getByUnidadeId: async (id) => {
        const URL = `${apiLink}api/condensador/unidade/${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                throw new Error(error.message);
            })
    },

}

export default apiCondensadores;

