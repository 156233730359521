import Login from './pages/login';
import EscolhaUnidade from './pages/escolha_unidade';
import MonitoramentoGeral from './pages/monitoramento/geral';
import SimulacaoUnidade from './pages/simulacao-unidade';
import MonitoramentoEnergia from './pages/monitoramento/energia';
import CargasTermicas from './pages/monitoramento/cargas-termicas';
import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import NotFound from './pages/NotFound';
import GrupoForm from './pages/admin/componentes/grupoForm';
import { ToastContextProvider } from './contexts/ToastContext';
import './globals.css';
import Admin from './pages/admin';
import UnidadeForm from './pages/admin/componentes/unidadeForm';
import Layout from './components/base/Layout';
import EmpresaForm from './pages/admin/componentes/empresaForm';
import UsuarioForm from './pages/admin/componentes/usuarioForm';
import CondensadorForm from './pages/admin/componentes/condensadorForm';
import ModeloCompressorForm from './pages/admin/componentes/modeloCompressorForm';
import TipoOperacaoCompressorForm from './pages/admin/componentes/tipoOperacaoCompressor';
import CompressorForm from './pages/admin/componentes/compressorForm';
import RegimeForm from './pages/admin/componentes/regimeForm';
import CondensacaoForm from './pages/admin/componentes/condensacaoForm';
import MarcaForm from './pages/admin/componentes/marcaForm';
import AmbienteForm from './pages/admin/componentes/ambienteForm';
import BombaForm from './pages/admin/componentes/bombaForm';
import VentiladorForm from './pages/admin/componentes/ventiladorForm';
import ModeloForm from './pages/admin/componentes/modeloForm';
import ChillerForm from './pages/admin/componentes/chillerForm';
import RecuperarSenha from './pages/recuperar_senha';
import ResetPassword from './pages/recuperar_senha/ResetPassword';
import Suporte from './pages/suporte';
import ResumoUnidade from './pages/resumo_unidade';
import EvaporadorForm from './pages/admin/componentes/evaporadorForm';
import MaquinaGeloForm from './pages/admin/componentes/maquinaGeloForm';
import SistemaAguaGelaForm from './pages/admin/componentes/sistemaAguaForm';
import Ambiente from './pages/relatorio/Ambiente';
import Compressor from './pages/relatorio/Compressor';
import Regime from './pages/relatorio/Regime';
import Condensador from './pages/relatorio/Condensador';

export default function App() {
	return (
		<ToastContextProvider>
			<AuthProvider>
				<Routes>
					<Route path="/" element={<Layout />}>
						<Route path="/login" element={<Login />} />
						<Route
							path="/recuperar-senha"
							element={<RecuperarSenha />}
						/>
						<Route
							path="/reset-password"
							element={<ResetPassword />}
						/>
						<Route
							path="/suporte"
							element={<Suporte />}
						/>

						<Route path="/admin" element={<Admin />}>
							<Route
								path="/admin/usuario/:page"
								element={<UsuarioForm />}
							/>
							<Route
								index
								path="/admin/grupo/:page"
								element={<GrupoForm />}
							/>
							<Route
								path="/admin/empresa/:page"
								element={<EmpresaForm />}
							/>
							<Route
								path="/admin/unidade/:page"
								element={<UnidadeForm />}
							/>
							<Route
								path="/admin/chiller/:page"
								element={<ChillerForm />}
							/>
							<Route
								path="/admin/regime/:page"
								element={<RegimeForm />}
							/>
							<Route
								path="/admin/maquina-gelo/:page"
								element={<MaquinaGeloForm />}
							/>
							<Route
								path="/admin/sistema-agua-gelada/:page"
								element={<SistemaAguaGelaForm />}
							/>
							<Route
								path="/admin/ambiente/:page"
								element={<AmbienteForm />}
							/>
							<Route
								path="/admin/evaporador/:page"
								element={<EvaporadorForm />}
							/>
							<Route
								path="/admin/condensacao/:page"
								element={<CondensacaoForm />}
							/>
							<Route
								path="/admin/marca/:page"
								element={<MarcaForm />}
							/>
							<Route
								path="/admin/modelo/:page"
								element={<ModeloForm />}
							/>
							<Route
								path="/admin/condensador/:page"
								element={<CondensadorForm />}
							/>
							<Route
								path="/admin/bomba/:page"
								element={<BombaForm />}
							/>
							<Route
								path="/admin/ventilador/:page"
								element={<VentiladorForm />}
							/>
							{/* COMPRESSOR */}
							<Route
								path="/admin/compressor/:page"
								element={<CompressorForm />}
							/>
							<Route
								path="/admin/modeloCompressor/:page"
								element={
									<ModeloCompressorForm />
								}
							/>
							<Route
								path="/admin/tipoOperacaoCompressor/:page"
								element={
									<TipoOperacaoCompressorForm />
								}
							/>
							{/* FIM COMPRESSOR */}
						</Route>
						<Route
							path="/resumo-unidade"
							element={<ResumoUnidade />}
						/>
						<Route
							path="/escolha-unidade"
							element={<EscolhaUnidade />}
						/>
						<Route
							path="/monitoramento/geral"
							element={<MonitoramentoGeral />}
						/>
						<Route
							path="/simulacao-unidade"
							element={<SimulacaoUnidade />}
						/>
						<Route
							path="/monitoramento/energia"
							element={<MonitoramentoEnergia />}
						/>
						<Route
							path="/monitoramento/cargas-termicas"
							element={<CargasTermicas />}
						/>
						<Route 
							path="/relatorio/ambiente" 
							element={<Ambiente />} 
						/>
						<Route 
							path="/relatorio/compressor" 
							element={<Compressor />} 
						/>
						<Route 
							path="/relatorio/condensador" 
							element={<Condensador />} 
						/>
						<Route 
							path="/relatorio/regime" 
							element={<Regime />} 
						/>
						<Route path="*" element={<NotFound />} />
					</Route>
				</Routes>
			</AuthProvider>
		</ToastContextProvider>
	);
}
