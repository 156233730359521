import React, { useState, useEffect, useCallback } from 'react';
import StatusOnOff from '../../common/StatusOnOff';
import Table from '../../common/table';

import apiCondensadores from '../../../services/apis/apiCondensadores';
import NoEquipAlert from '../../common/returns/NoEquipAlert';
import getCurrentUnidadeId from '../../../funcs/getCurrentUnidadeId';

const TableCondensadores = ({ condensacao }) => {
	let condensacaoId = condensacao[0]?.id;
	const [condensadoresData, setCondensadoresData] = useState([]);

	const fetchData = useCallback(async () => {
		try {
			const unidadeId = getCurrentUnidadeId();
			if (!condensacaoId) return;
			const condensadores = (await apiCondensadores.getByUnidadeId(unidadeId)).data;
			const condensadoresData = condensadores.map(
				(condensador) => {
					const quantidadeTotalJanelas =
						condensador.janelas.reduce(
							(total, janela) =>
								total + janela.quantidade,
							0
						);

					const areaTotalJanelas =
						condensador.janelas.reduce(
							(totalArea, janela) =>
								totalArea +
								janela.altura *
									janela.largura *
									janela.quantidade,
							0
						);

					return {
						...condensador,
						quantidadeTotalJanelas,
						areaTotalJanelas,
					};
				}
			);

			setCondensadoresData(condensadoresData);

		} catch (error) {
			console.error(
				`Erro fetching condensadores [SobreUnidade - Condensadores]: ${error}`
			);
		}
	}, [condensacaoId]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	return (
		<>
			<div className="w-full">
				{condensadoresData.length > 0 ? (
					<Table.Root>
						<Table.Head>
							<Table.Header>Nome</Table.Header>
							<Table.Header>Marca</Table.Header>
							<Table.Header>Modelo</Table.Header>
							<Table.Header>
								Janelas
								<span className="text-xs px-[1px] inline lowercase">
									(qtd)
								</span>
							</Table.Header>
							<Table.Header>
								Area{' '}
								<p className="text-xs px-[1px] inline lowercase">
									(Arestas)
								</p>
							</Table.Header>
							<Table.Header>
								Capacidade Nominal
							</Table.Header>
						</Table.Head>
						<Table.Body>
							{condensadoresData.map(
								(condensador) => (
									<Table.Row
										key={condensador.id}
									>
										<Table.Data className="font-bold text-accent">
											{
												condensador.nome
											}
										</Table.Data>
										<Table.Data>
											{condensador
											.modelo
											.marca
											.nome}
										</Table.Data>
										<Table.Data>
											{condensador
												.modelo
												.nome}
										</Table.Data>
										<Table.Data>
											{condensador
												.janelas
												.length >
											0
												? condensador.quantidadeTotalJanelas
												: '-'}
										</Table.Data>
										<Table.Data>
											{condensador
												.janelas
												.length >
											0
												? condensador.areaTotalJanelas.toLocaleString(
														'pt-BR',
														{
															maximumFractionDigits: 2,
														}
												  ) +
												  ' m²'
												: '-'}
										</Table.Data>
										<Table.Data>
											{condensador.capacidadeNominal.toLocaleString(
												'pt-BR',
												{
													maximumFractionDigits: 2,
												}
											) +
												' Kcal/ h'}
										</Table.Data> 
									</Table.Row>
								)
							)}
						</Table.Body>
					</Table.Root>
				) : (
					<NoEquipAlert equipments="condensadores" />
				)}
			</div>
		</>
	);
};

export default TableCondensadores;
