import { useContext, useEffect, useState } from "react";
import getCurrentUnidadeId from "../../funcs/getCurrentUnidadeId";
import apiRelatorio from "../../services/apis/apiRelatorio";
import CardLayout from "../../components/common/CardLayout";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../components/ui/accordion";
import DefaultChart from "../../components/relatorio/DefaultChart";
import NoDataAlert from "../../components/common/returns/NoDataAlert";
import { DatePicker } from "../../components/ui/datepicker";
import { Button } from "../../components/ui/button";
import { getClientTimeZoneOffset } from "../../funcs";
import DateIntervalPopOver from "../../components/relatorio/DateIntervalPopOver";
import { FaFileExcel, FaSpinner } from "react-icons/fa";
import { ToastContext } from "../../contexts/ToastContext";
import apiUnidade from "../../services/apis/apiUnidade";

export default function Ambiente() {
    const UNIDADE = getCurrentUnidadeId();
    const [dados, setDados] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [tempSelectedDate, setTempSelectedDate] = useState(new Date());
    const [regimes, setRegimes] = useState([]);
    const [isLoadingRelatorio, setIsLoadingRelatorio] = useState(false);
    const { erro } = useContext(ToastContext);
    const [unidade, setUnidade] = useState();

    useEffect(() => {
        const fetchData = async () => {
            const res = await apiUnidade.getById(UNIDADE);
            setUnidade(res.data);
        }
        fetchData();
    }, [UNIDADE]);

    const handleDateChange = (date) => {
        setTempSelectedDate(date);
    };

    const handleSearch = async () => {
        setSelectedDate(tempSelectedDate);
    };

    useEffect(() => {
        const fetchData = async () => {
            const dataFormatada = selectedDate?.toLocaleDateString('en-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            });

            const timeZoneOffset = getClientTimeZoneOffset();

            const fusoData = {
                data: dataFormatada,
                TimeZoneNumber: timeZoneOffset
            }

            const res = await apiRelatorio.relatorioAmbiente(UNIDADE, fusoData)
            const resRegimes = await apiRelatorio.relatorioRegime(UNIDADE, fusoData, 'dia');
            setDados(res.data);
            setRegimes(resRegimes.data);
        }
        fetchData();
    }, [selectedDate, UNIDADE]);

    const gerarRelatorio = async (tempSelectedDateDe, tempSelectedDateAte) => {
        try {
            setIsLoadingRelatorio(true);
            const timeZoneOffset = getClientTimeZoneOffset();

            const dataInfo = {
                dataInicio: tempSelectedDateDe,
                dataFim: tempSelectedDateAte,
                TimeZoneNumber: timeZoneOffset
            }

            const dataInicioFormatada = dataInfo.dataInicio.toLocaleString('pt-BR', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
				hour: '2-digit',
				minute: '2-digit',
				hour12: false 
			}).replaceAll(",", "_").replaceAll(" ", "");

            const dataFinalFormatada = dataInfo.dataFim.toLocaleString('pt-BR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            }).replaceAll(",", "_").replaceAll(" ", "");

            //se data inicio for maior que data final
            if (dataInfo.dataInicio > dataInfo.dataFim) {
                erro('Data de início precisa ser maior que data final');
                return;
            }

            const response = await apiRelatorio.relatorioLeituraAmbiente(unidade.id, dataInfo);
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', `Leituras_Ambientes_${unidade.nome}_${dataInicioFormatada}-${dataFinalFormatada}.xlsx`);

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (error) {
            erro(error.message);
        } finally {
            setIsLoadingRelatorio(false);
        }
    }

    return (
        <section className="flex flex-col pb-16">
            <div className="flex items-center justify-center">
                <div className="flex justify-center items-center w-full p-4 gap-2">
                    <DatePicker
                        selectedDate={tempSelectedDate}
                        onDateChange={handleDateChange}
                    />
                    <Button onClick={handleSearch}>
                        Buscar
                    </Button>
                    <DateIntervalPopOver onGerarRelatorio={gerarRelatorio} disabled={isLoadingRelatorio}>
                        {
                            isLoadingRelatorio ? 
                            <div className="animate-spin">
                                <FaSpinner size={18} />
                            </div>
                            :
                            <FaFileExcel size={18} /> 
                        }
                        
                    </DateIntervalPopOver>
                </div>
                <div className="inline-flex w-32 justify-end px-2">
                    <p className="text-sm text-secondary drop-shadow-1xl">
                        {selectedDate
                            ? selectedDate.toLocaleDateString(
                                'pt-BR',
                                {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                }
                            )
                            : ''}
                    </p>
                </div>
            </div>
            <div className="flex flex-col gap-4 py-2">
                {

                    dados.map((ambiente) => (
                        <CardLayout key={ambiente.id}>
                            <Accordion
                                type="single"
                                defaultValue={`item-${ambiente.id}`}
                                collapsible={true}
                            >
                                <AccordionItem
                                    value={`item-${ambiente.id}`}
                                >
                                    <div className="font-semibold">
                                        <AccordionTrigger>
                                            <div className="w-full flex items-center justify-center text-sm lg:text-2xl drop-shadow-1xl">
                                                {ambiente &&
                                                    <div
                                                        className={`uppercase`}
                                                    >
                                                        <p>
                                                            <span className="capitalize">
                                                                {
                                                                    ambiente.nome
                                                                }
                                                            </span>
                                                        </p>
                                                    </div>
                                                }
                                            </div>
                                        </AccordionTrigger>
                                    </div>
                                    <AccordionContent>
                                        <div className="flex flex-col lg:flex-row gap-4 px-4">
                                            {
                                                ambiente.leiturasAmbientes.length === 0 ?
                                                    <div className="flex flex-col gap-4 justify-center w-full items-center" key={ambiente.id}>
                                                        <div className="w-[300px]">
                                                            <NoDataAlert />
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="flex flex-col lg:flex-row justify-center items-center w-full px-2" key={ambiente.id}>
                                                        <div className="flex flex-col items-center w-full h-full lg:w-7/12 2xl:3/4">
                                                            <DefaultChart chartDataProps={ambiente} chartType={"ambiente"} regime={regimes.find(({ id }) => id === ambiente.regimeId)} />
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </AccordionContent>
                                </AccordionItem>
                            </Accordion>
                        </CardLayout>
                    ))
                }
            </div>
        </section>
    );
}