import React, { useState, useEffect, useCallback } from 'react';
import Table from '../../common/table';
import NoEquipAlert from '../../common/returns/NoEquipAlert';
import apiSistemaAguaGelada from '../../../services/apis/apiSistemaAguaGelada';
import getCurrentUnidadeId from '../../../funcs/getCurrentUnidadeId';

const TableSistemas = ({ regimes }) => {
	const [sistemasData, setSistemasData] = useState([]);

	const fetchData = useCallback(async () => {
		try {
			const unidadeId = getCurrentUnidadeId();
			if (!regimes) return;

			const sistemas = (await apiSistemaAguaGelada.getByUnidadeId(unidadeId)).data;

			const sistemasData = sistemas.map((sistema) => {
				const regimeName = regimes.find(
					(regime) => regime.id === sistema.regimeId
				).nome;
				return {
					...sistema,
					regime: regimeName ? regimeName : '-',
				};
			});

			setSistemasData(sistemasData);

		} catch (error) {
			console.error(
				`Error fetching sistemas [SobreUnidade - Sistemas]: ${error}`
			);
		}
	}, [regimes]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	return (
		<div className="w-full">
			{sistemasData.length > 0 ? (
				<Table.Root>
					<Table.Head>
						<Table.Header>Nome</Table.Header>
						<Table.Header>Regime</Table.Header>
						<Table.Header>Marca</Table.Header>
						<Table.Header>Modelo</Table.Header>
						<Table.Header>Vazao</Table.Header>
						<Table.Header>Temp. Entrada</Table.Header>
						<Table.Header>Temp. Saida</Table.Header>
						<Table.Header>Potência</Table.Header>
						<Table.Header>Carga Térmica</Table.Header>
					</Table.Head>
					<Table.Body>
						{sistemasData.map((sistema) => (
							<Table.Row key={sistema.id}>
								<Table.Data>
									{sistema.nome}
								</Table.Data>
								<Table.Data>
									{sistema.regime}
								</Table.Data>
								<Table.Data>
									{sistema.modelo.nome}
								</Table.Data>
								<Table.Data>
									{sistema.modelo.marca.nome}
								</Table.Data>
								<Table.Data>
									{sistema.vazao.toLocaleString(
										'pt-BR',
										{
											maximumFractionDigits: 2,
										}
									) + ' m³/ h'}
								</Table.Data>
								<Table.Data>
									{sistema.temperaturaEntrada.toLocaleString(
										'pt-BR',
										{
											maximumFractionDigits: 2,
										}
									) + ' °C'}
								</Table.Data>
								<Table.Data>
									{sistema.temperaturaSaida.toLocaleString(
										'pt-BR',
										{
											maximumFractionDigits: 2,
										}
									) + ' °C'}
								</Table.Data>
								<Table.Data>
									{sistema.potencia.toLocaleString(
										'pt-BR',
										{
											maximumFractionDigits: 2,
										}
									) + ' CV'}
								</Table.Data>
								<Table.Data>
									{sistema.cargaTermica.toLocaleString(
										'pt-BR',
										{
											maximumFractionDigits: 2,
										}
									) + ' Kcal/ h'}
								</Table.Data>
							</Table.Row>
						))}
					</Table.Body>
				</Table.Root>
			) : (
				<NoEquipAlert equipments="sistemas de água" />
			)}
		</div>
	);
};

export default TableSistemas;
