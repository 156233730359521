import React from 'react';
import CardLayout from '../../common/CardLayout';
import TableAlarmCompressor from './TableAlarmCompressor';
import TableAlarmRegime from './TableAlarmRegime';


const ListAlarms = ({listaAlarmes}) => {
	
	return (
		<>
			<CardLayout>
				<div className="flex justify-center align-items-center">
					<h1 className="py-2 md:text-2xl text-lg font-semibold text-accent drop-shadow-1xl">
						Relatório de Eventos{' '}
						<div className="inline-block">
							{listaAlarmes.length > 0 ? (
								<span className="text-alarm">
									<span className="animate-ping inline-block h-4 w-4 rounded-full ring-2 ring-alarm/40 bg-alarm mx-4"></span>
									({listaAlarmes.filter(({tipoEquipamento}) => (tipoEquipamento === "Regime" || tipoEquipamento === "Compressor")).length})
								</span>
							) : (
								<span></span>
							)}
						</div>
					</h1>
				</div>
				<div className="flex flex-col lg:flex-row gap-1 gap-y-2 max-h-[110px]">
					<div className="lg:w-1/2 max-h-full overflow-y-auto scrollbar-thin scrollbar-track-primary scrollbar-thumb-darkaccent">
						<TableAlarmCompressor
							listaAlarmeCompressores={listaAlarmes.filter(
								(elemento) =>
									elemento?.tipoEquipamento ===
									'Compressor'
							)}
						/>
					</div>
					<div className="lg:w-1/2 max-h-full overflow-y-auto scrollbar-thin scrollbar-track-primary scrollbar-thumb-darkaccent">
						<TableAlarmRegime
							listaAlarmesRegime={listaAlarmes.filter(
								(elemento) =>
									elemento?.tipoEquipamento ===
										4 ||
									elemento?.tipoEquipamento ===
										'Regime'
							)}
						/>
					</div>
				</div>
			</CardLayout>
		</>
	);
};

export default ListAlarms;
