import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Calendar as CalendarIcon } from 'lucide-react';
import { Button } from './button';
import { Calendar } from './calendar';
import { Popover, PopoverContent, PopoverTrigger } from './popover';
import { cn } from '../../lib/utils/utils';

export function DateTimePicker({ onDateChange }) {
	const [date, setDate] = useState();
	const [hour, setHour] = useState("00");
	const [minute, setMinute] = useState("00");

	useEffect(() => {
		if(date) {
			const newDate = new Date(date);
			newDate.setHours(hour);
			newDate.setMinutes(minute);
			onDateChange(newDate);
		}
	}, [date, hour, minute, onDateChange]);

	const handleDateChange = (newDate) => {
		setDate(newDate);
	};

	const handleHourChange = (e) => {
		const number = e.target.value;
		if(number.length > 2) return;
		setHour(e.target.value);
	}

	const handleMinuteChange = (e) => {
		const number = e.target.value;
		if(number.length > 2) return;
		setMinute(e.target.value);
		handleDateChange(date);
	}

	const showHours = () => {
		if(hour === '') {
			return '00';
		}
		if(hour.length === 1) {
			return `0${hour}`;
		}
		return hour;
	}


	const showMinutes = () => {
		if(minute === '') {
			return '00';
		}
		if(minute.length === 1) {
			return `0${minute}`;
		}
		return minute;
	}

	return (
		<Popover>
			<PopoverTrigger asChild>
				<Button
					variant={'label'}
					className={cn(
						'w-[280px] justify-start text-left font-normal text-secondary',
						!date && 'text-muted-foreground'
					)}
				>
					<CalendarIcon className="text-white mr-2 h-4 w-4" />
					{date ? (
						format(date, 'PPP', { locale: ptBR }) + `, ${showHours()}:${showMinutes()}`
					) : (
						<span className="text-secondary">
							Escolha uma data
						</span>
					)}
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-auto p-0">
                <div className='pb-4'>
					<Calendar
						mode="single"
						selected={date}
						onSelect={handleDateChange}
						initialFocus
					/>
					<div className='w-full flex justify-center items-center'>
						<input type='number' className='w-[40px] h-[40px] rounded-md border-accent border bg-transparent text-center text-[20px]' value={hour} onChange={(e) => handleHourChange(e)} maxLength={2}/>
						<p className='mx-2'>:</p>
						<input type='number' className='w-[40px] h-[40px] rounded-md border-accent border bg-transparent text-center text-[20px]' value={minute} onChange={(e) => handleMinuteChange(e)}/>
					</div>
                </div>
			</PopoverContent>
		</Popover>
	);
}
