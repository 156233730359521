// import dynamic from 'next/dynamic';
import ApexChart from 'react-apexcharts';

// const ApexChart = dynamic(() => import('react-apexcharts'), { ssr: false });

export function GaugeChart({ valor, label }) {
	let corPrimaria = '#54f754';
	let corSecundaria = '#2D852D';

	if (valor > 100) {
		corPrimaria = '#F7E80C';
		corSecundaria = '#A17A08';
	} else if (valor < 0) {
		corPrimaria = '#FF1900';
		corSecundaria = '#7C1200';
	}
	const option = {
        labels:[label],

		chart: {
			animations: {
				enabled: false,
				easing: 'easeout',
				speed: 200,
			},
			type: 'radialBar',
			offsetY: 0,
			height: 200,
			sparkline: {
				enabled: true,
			},
		},
		plotOptions: {
			radialBar: {
				startAngle: -90,
				endAngle: 90,
				track: {
					background: '#9ba5cc',
					margin: 5,
					dropShadow: {
						enabled: true,
						top: 0,
						left: 1,
						color: corPrimaria,
					},
				},
				dataLabels: {
                    name: {
                        show: true,
                        color: '#FDFDFD',
                        offsetY: 30,
					},
					value: {
						offsetY: -10,
						color: '#FDFDFD',
						fontSize: '24px',
						fontWeight: 'bold',
						formatter: function (val) {
							return (
								valor?.toLocaleString(
									'pt-BR',
									{
										maximumFractionDigits: 2,
									} 
								) + "%"
							);
						},
					},
				},
                
			},
		},
		grid: {
			padding: {
				top: -10,
			},
		},
		fill: {
			type: 'gradient',
			gradient: {
				inverseColors: false,
				opacityFrom: 1,
				opacityTo: 1,
				colorStops: [
					{
						offset: 20,
						color: corPrimaria,
						opacity: 1,
					},
					{
						offset: 100,
						color: corSecundaria,
						opacity: 1,
					},
				],
			},
		},
	};

	const series = [
		[
			valor
		],
	];


	return (
		<>
			<ApexChart
				type="radialBar"
				options={option}
				series={series}
				height={200}
				width="90%"
			/>
		</>
	);
}
