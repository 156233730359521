'use client';
import Chart from 'react-apexcharts';

const DefaultChartRegime = ({ chartDataProps, chartType }) => {
	const leitura = chartDataProps.leiturasRegimes;
	const datas = leitura.map((leitura) => (leitura.dataHora));
	const options = {
		chart: {
			id: 'basic-line',
			type: 'line',
			dropShadow: {
				enabled: true,
				color: '#123',
				top: 18,
				left: 7,
				blur: 10,
				opacity: 0.5,
			},
			toolbar: {
				show: false,
				tools: {
					download: false,
					selection: false,
					zoom: true,
					zoomin: true,
					zoomout: true,
					pan: false,
					reset: true,
				},

			},
			animations: {
				enabled: false,
				easing: 'easeinout',
				speed: 800,
				animateGradually: {
					enabled: true,
					delay: 150,
				},
				dynamicAnimation: {
					enabled: true,
					speed: 350,
				},
			},
		},
		grid: {
			show: false,
			borderColor: '#40475D',
			strokeDashArray: 0,
		},
		stroke: {
			show: true,
			curve: 'smooth',
			lineCap: 'butt',
			width: 1.2,
			dashArray: 0,
		},
		states: {
			hover: {
				filter: 'none',
			},
		},
		markers: {
			size: 0,
		},
		xaxis: {
            categories: leitura.map((leitura) => (leitura.dataHora)),
			type: 'time',
			tickAmount:getTickAmount(),
			position: 'bottom',
			labels: {
				show: true,
				style: {
					colors: 'rgba(12, 171, 245, 0.30)',
					fontSize: '12px',
					fontStyle: 'normal',
				},
				formatter: function (value) {
					return chartType === "dia" ? new Date(value).toLocaleTimeString('pt-BR', {
						hour: '2-digit',
						minute: '2-digit',
						second: '2-digit'
					}) : 
						new Date(value).toLocaleDateString('pt-BR', {
						day: '2-digit',
						month: '2-digit',
						year: 'numeric',
					  })
				},
				
			},
			
			axisTicks: {
				show: false,
				color: '#40475D',
			},
			axisBorder: {
				show: false,
			},
		},
		yaxis: {
			labels: {
				show: true,
				style: {
					colors: 'rgba(12, 171, 245, 0.30)',
					fontSize: '14px',
				},
				formatter: function (val) {
					return val?.toLocaleString(
						'pt-BR',
						{
							maximumFractionDigits: 2,
						}
					) + ' bar';
				},
			},
			axisTicks: {
				show: false,
				color: '#40475D',
			},
			axisBorder: {
				show: false,
			},
		},
		tooltip: {
			theme: 'dark',
			x: {
				formatter: function (_, { series, seriesIndex, dataPointIndex, w }) {
					return new Date(datas[dataPointIndex]).toLocaleDateString('pt-BR', {
						day: '2-digit',
						month: '2-digit',
						year: 'numeric',
						hour: '2-digit',
						minute: '2-digit',
						second: '2-digit'
					  });
				}
			},
			y: {
				formatter: function(val) {
				  return val?.toLocaleString(
					'pt-BR',
					{
						maximumFractionDigits: 2,
					}
				)  
				}
			}
		},
		dataLabels: {
			enabled: false,
		},
		legend: {
			show: true,
			position: 'top',
			fontStyle: 'bold',
			fontColor: 'white',
			fontSize: '16px',
			labels: {
				colors: 'white',
				useSeriesColors: false,
			},
			onItemClick: {
				toggleDataSeries: true,
			},
			onItemHover: {
				highlightDataSeries: true,
			},
		},
	};

	let series;

	series = [
		{
			name: "pressão",
			data: leitura.map((leitura) => (leitura.pressaoAtual))
		},
		{
			name: "pressão máxima",
			data: leitura.map((_) => (chartDataProps.rangeMax))
		},
		{
			name: "pressão mínima",
			data: leitura.map((_) => (chartDataProps.rangeMin))
		},
		
	];
	function getTickAmount(){
		if(chartType === "dia"){
			return 12;
		} else if (chartType === "semana"){
			return 7;
		} else if (chartType === "mes"){
			return 20;
		}
	}

	if (!chartDataProps) return null;

	return (
		<div className="bg-transparent w-full h-full p-2">
			<Chart
				options={options}
				series={series}
				width="100%"
				height={320}
			/>
		</div>
	);
};

export default DefaultChartRegime;
