import React, { useState, useEffect, useCallback } from 'react';
import StatusOnOff from '../../common/StatusOnOff';
import Table from '../../common/table';
import NoEquipAlert from '../../common/returns/NoEquipAlert';
import InputGeneric from '../../common/InputGeneric';
import apiCompressores from '../../../services/apis/apiCompressores';
import getCurrentUnidadeId from '../../../funcs/getCurrentUnidadeId';

const TableCompressores = ({ regimes }) => {
	const [compressoresData, setCompressoresData] = useState([]);


	const fetchData = useCallback(async () => {
		try {
			if (!regimes) return;
			const unidadeId = getCurrentUnidadeId();
			const compressores = (await apiCompressores.getByUnidadeId(unidadeId)).data;

			const compressoresData = compressores.map((compressor) => ({
				...compressor,
				regimeSuccao: regimes.find(
					(regime) => regime.id === compressor.regimeSuccaoId
				)?.nome,
				regimeDescarga: regimes.find(
					(regime) => regime.id === compressor.regimeDescargaId
				)?.nome,
			}));

			setCompressoresData(compressoresData);
		} catch (error) {
			console.error(
				`Error fetching compressores [SobreUnidade - Compressores]: ${error}`
			);
		}
	}, [regimes]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	return (
		<>
			<div className="w-full">
				{compressoresData.length > 0 ? (
					<Table.Root>
						<Table.Head>
							<Table.Header>Nome</Table.Header>
							{/* <Table.Header>Status</Table.Header> */}
							<Table.Header>Marca</Table.Header>
							<Table.Header>Modelo</Table.Header>
							<Table.Header>
								Regime Sucção
							</Table.Header>
							<Table.Header>
								Regime Descarga
							</Table.Header>
							<Table.Header>Operação</Table.Header>
							<Table.Header>
								Capacidade
								<p className="text-xs px-[1px] inline lowercase">
									(Kcal/ h){' '}
								</p>
							</Table.Header>
							<Table.Header>Potência</Table.Header>
						</Table.Head>
						<Table.Body>
							{compressoresData.map(
								(compressor) => (
									<Table.Row
										key={compressor.id}
									>
										<Table.Data className="font-bold text-accent">
											{
												compressor.nome
											}
										</Table.Data>
										{/* <Table.Data>
											<StatusOnOff
												status={
													compressor
														.leitura
														?.ligado ||
													false
												}
											/>
										</Table.Data> */}
										<Table.Data>
											{compressor.modeloCompressor
													.marca
													.nome}
										</Table.Data>
										<Table.Data>
												{compressor
													.modeloCompressor
													.nome}
										</Table.Data>
										<Table.Data>
											{compressor.regimeSuccao}
										</Table.Data>
										<Table.Data>
											{compressor.regimeDescarga &&
												compressor.regimeDescarga}
										</Table.Data>
										<Table.Data>
											{compressor
													.tipoOperacaoCompressor
													.nome}
										</Table.Data>
										<Table.Data>
											{compressor
												.capacidadeNominal? (
												<>
													<InputGeneric
														className="max-w-[97px] mx-auto"
														value={compressor.capacidadeNominal.toLocaleString(
															'pt-BR',
															{
																maximumFractionDigits: 2,
															}
														)}
														type="string"
														id={
															'leituraCapacidade' +
															compressor.id
														}
														readOnly
													/>
												</>
											) : (
												'-'
											)}
										</Table.Data>
										<Table.Data>
											{ compressor
												.potenciaNominal ? (
												<>
													<InputGeneric
														className="max-w-[88px] mx-auto"
														value={
															compressor.potenciaNominal.toLocaleString(
																'pt-BR',
																{
																	maximumFractionDigits: 2,
																}
															) +
															' CV'
														}
														type="string"
														id={
															'leituraPotenciaKw' +
															compressor.id
														}
														readOnly
													/>
												</>
											) : (
												'-'
											)}
										</Table.Data>
									</Table.Row>
								)
							)}
						</Table.Body>
					</Table.Root>
				) : (
					<NoEquipAlert equipments="compressores" />
				)}
			</div>
		</>
	);
};

export default TableCompressores;
