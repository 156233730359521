export const getPctg = (value, pctg) => {
    if(pctg === 0) return 0;
    
    return (value / 100) * pctg;
}

export function getClientTimeZoneOffset() {
    const offsetInMinutes = new Date().getTimezoneOffset();
    const offsetInHours = -offsetInMinutes / 60;
    return offsetInHours;
}