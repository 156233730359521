import { useState, useEffect, useCallback } from 'react';
import CardLayout from '../../common/CardLayout';
import ChartBarDefault from './charts/ChartBarDefault';

import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '../../ui/accordion';
import apiTbu from '../../../services/apis/apiTbu';
import getCurrentUnidadeId from '../../../funcs/getCurrentUnidadeId';
import apiCondensadores from '../../../services/apis/apiCondensadores';
import apiSimulacaoUnidade from '../../../services/apis/apiSimulacaoUnidade';
import apiUltimaLeituraCondensacao from '../../../services/apis/Leituras/apiUltimaLeituraCondensacao';

export default function Condensacao({ ListContent, calculosCompressores }) {
	const condensacao = ListContent[0];
	const unidade = getCurrentUnidadeId();
	const [tbu, setTbu] = useState(0);

	const [temperaturaCondensacao, setTemperaturaCondensacao] = useState();
	const [capacidadeTermicaDisponivel, setCapacidadeTermicaDisponivel] =
		useState([0]);
	const [capacidadeTermicaNecessaria, setCapacidadeTermicaNecessaria] =
		useState([0]);

	
	const fetchData = useCallback(async () => {
		try {
			//TODO: CRIAR ENDPOINT NO BACKEND QUE TRAGA O CALCULO DE CAPACIDADE DISPONIVEL DE TODOS OS CONDENSADORES DE UMA ÚNICA VEZ PARA EVITAR UM LOOP DE REQUISIÇÕES
			const resTbu = await apiTbu.ultimaLeitura(unidade);
			if (resTbu.status !== 204) {
				setTbu(resTbu.data.tbuCalculado);
			} else {
				console.warn('Nenhuma leitura de TBU encontrada.');
			}

			const res = await apiCondensadores.getByRegimeId(
				condensacao.id
			);
			const condensadores = res.data;

			const resCalculos = condensadores.map(async (cd) => {
				return await apiSimulacaoUnidade.getCalculosCondensador(
					tbu,
					cd.capacidadeNominal,
					temperaturaCondensacao
				);
			});

			const resultados = await Promise.all(resCalculos);
			const soma = resultados.reduce(
				(valorInicial, calculo) => valorInicial + calculo.data,
				0
			);

			setCapacidadeTermicaDisponivel([soma]);
		} catch (error) {
			console.log(error);
		}
	}, [condensacao.id, tbu, temperaturaCondensacao, unidade]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	useEffect(() => {
		let somaPotencias = 0;
		let somaCapacidadesCompressoresComRegimeDescargaCondensacao = 0;

		calculosCompressores.forEach((calculo) => {
			const {
				regimeDescargaId,
				potenciaRealCalculada,
				capacidadeRealCalculada,
			} = calculo;
			somaPotencias += potenciaRealCalculada;

			if (regimeDescargaId === condensacao.id) {
				somaCapacidadesCompressoresComRegimeDescargaCondensacao +=
					capacidadeRealCalculada;
			}
		});
		setCapacidadeTermicaNecessaria([
			somaCapacidadesCompressoresComRegimeDescargaCondensacao +
				somaPotencias * 860,
		]);
	}, [calculosCompressores, condensacao.id]);

	useEffect(() => {
		const fetchCondensacaoData = async () => {
			try {
				const leituraCondensacao = (await apiUltimaLeituraCondensacao.fetchApi(condensacao.id)).data;
				setTemperaturaCondensacao(leituraCondensacao.temperaturaCondensacao);	
			} catch (error) {

				
			}
		}
		fetchCondensacaoData();
	},[condensacao.id]);

	return (
		<>
			<CardLayout>
				<Accordion
					type="single"
					defaultValue="item-01"
					collapsible={true}
				>
					<AccordionItem value="item-01">
						<div className="font-semibold">
							<AccordionTrigger>
								<div className="w-full flex flex-col items-center justify-center text-sm lg:text-2xl drop-shadow-1xl">
									<p className="text-accent uppercase">
										Condensação
									</p>
								</div>
							</AccordionTrigger>
						</div>
						{/* Conteudo que o accordion minimiza */}
						<AccordionContent>
							<div className="flex w-full items-center justify-center text-sm lg:text-2xl drop-shadow-1xl py-2">
								<div className="flex border rounded border-accent gap-10 px-8">
									<p className="text-fontColor">
										Tbu:{' '}
										<span className="text-accent font-bold">
											{tbu?.toLocaleString(
												'pt-BR',
												{
													maximumFractionDigits: 2,
												}
											)}
											°C
										</span>
									</p>
									<p className="text-fontColor">
										Temperatura de
										condensação:{' '}
										<span className="text-accent font-bold">
											{temperaturaCondensacao?.toLocaleString(
												'pt-BR',
												{
													maximumFractionDigits: 2,
												}
											)}
											°C
										</span>
									</p>
								</div>
							</div>
							<div className="flex flex-col lg:flex-row gap-4 px-4 justify-center">
								<div className="flex flex-col justify-center w-full lg:w-5/12 lg:order-2">
									<div className="flex w-full items-center justify-around">
										<div className="py-2 2xl:text-2xl lg:text-lg md:text-sm font-semibold text-secondary drop-shadow-1xl">
											Carga térmica
											disponível
											<p className="text-accent">
												(
												<span className="text-secondary">
													{' '}
													Condensadores{' '}
												</span>
												<span className="text-accent">
													)
												</span>
											</p>
										</div>
										<span className="text-lg md:text-2xl font-bold drop-shadow-1xl bg-gradient-to-r from-[#008000dd] via-[#00FF00] to-[#008000dd] inline-block text-transparent bg-clip-text ">
											{capacidadeTermicaDisponivel
												? capacidadeTermicaDisponivel?.toLocaleString(
														'pt-BR',
														{
															maximumFractionDigits: 2,
														}
												  )
												: ' 0 '}
											{' Kcal/ h'}
										</span>
									</div>
									<div className="flex w-full items-center justify-around">
										<div className="py-2  2xl:text-2xl lg:text-lg md:text-sm font-semibold text-secondary drop-shadow-1xl">
											Carga térmica
											necessária
											<p className="text-accent">
												(
												<span className="text-secondary">
													{' '}
													Compressores{' '}
												</span>
												<span className="text-accent">
													)
												</span>
											</p>
										</div>
										<span className="text-lg md:text-2xl font-bold drop-shadow-1xl bg-gradient-to-r from-accent via-indigo-500 to-accent inline-block text-transparent bg-clip-text">
											{capacidadeTermicaNecessaria
												? capacidadeTermicaNecessaria?.toLocaleString(
														'pt-BR',
														{
															maximumFractionDigits: 2,
														}
												  )
												: ' 0 '}{' '}
											{' Kcal/ h'}
										</span>
									</div>
								</div>
								<div className="flex flex-col w-full lg:w-5/12 lg:order-1 py-4">
									<ChartBarDefault
										chartDataProps={{
											condensadoresNecessario:
												capacidadeTermicaNecessaria,
											condensadoresDisponivel:
												capacidadeTermicaDisponivel,
										}}
										typeChart="cargasTermicasCondensacao"
										horizontal={true}
										offsetX={0}
									/>
								</div>
							</div>
						</AccordionContent>
					</AccordionItem>
				</Accordion>
			</CardLayout>
		</>
	);
}
