import React, { useState, useEffect, useCallback } from 'react';
import Table from '../../common/table';
import NoEquipAlert from '../../common/returns/NoEquipAlert';

import apiBomba from '../../../services/apis/apiBomba';
import apiCondensador from '../../../services/apis/apiCondensadores';

const TableBombas = ({ unidadeId, regimes, condensacao }) => {
	const [bombasData, setBombasData] = useState([]);

	const fetchData = useCallback(async () => {
		if (!unidadeId && !condensacao && !regimes) return;
		try {
			const res = await apiBomba.getByUnidadeIdV2(unidadeId);

			if (res.data.length > 0) {

				const condensadorBombas = res.data.filter(
					(bomba) => bomba.tipoEquipamento === 'Condensador'
				);
				const condensadores = (await apiCondensador.getByUnidadeId(unidadeId)).data;

				const bombasData = condensadorBombas.map((bomba) => {
					return {
						...bomba,
						condensador: condensadores.find(
							(condensador) => condensador.id === bomba.condensadorId
						),
					}
				});

				//ordenar por nome do condensador
				bombasData.sort((a, b) => {
					if (a.condensador.nome < b.condensador.nome) {
						return -1;
					}
					if (a.condensador.nome > b.condensador.nome) {
						return 1;
					}
					return 0;
				});

				setBombasData(bombasData);
			}
		} catch (error) {
			console.error(`Error fetching bombas: ${error}`);
		}
	}, [unidadeId, condensacao, regimes]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	return (
		<div className="w-full">
			{bombasData.length > 0 ? (
				<Table.Root>
					<Table.Head>
						<Table.Header>Nome</Table.Header>
						{/* <Table.Header>Regime</Table.Header> */}
						<Table.Header>Equipamento</Table.Header>
						<Table.Header>Marca</Table.Header>
						<Table.Header>Modelo</Table.Header>
						<Table.Header>Potência</Table.Header>
					</Table.Head>

					<Table.Body>
						{bombasData.map((bomba) => (
							<Table.Row key={bomba.id}>
								<Table.Data>
									{bomba.nome}
								</Table.Data>
								{/* <Table.Data>
									{bomba.regime}
								</Table.Data> */}
								<Table.Data>
									{bomba.condensador.nome}
								</Table.Data>
								<Table.Data>
									{bomba.modelo.marca.nome}
								</Table.Data>
								<Table.Data>
									{bomba.modelo.nome}
								</Table.Data>
								<Table.Data>
									{bomba.potenciaNominal +
										' CV'}
								</Table.Data>
							</Table.Row>
						))}
					</Table.Body>
				</Table.Root>
			) : (
				<NoEquipAlert equipments="bombas" />
			)}
		</div>
	);
};

export default TableBombas;
