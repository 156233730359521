import React, { useState, useEffect, useCallback } from 'react';
import apiRegimes from '../../services/apis/apiRegimes';

import CardLayout from '../common/CardLayout';
import TitleCard from '../common/TitleCard';
import { FaTools } from 'react-icons/fa';

import TableCompressores from './tabs/TableCompressores.jsx';
import TableCondensadores from './tabs/TableCondensadores';
/* import TableEvaporadores from './tabs/TableEvaporadores'; */
import TableSistemas from './tabs/TableSistemas';
import TableMaquinasGelo from './tabs/TableMaquinasGelo';
import TableVentiladores from './tabs/TableVentiladores';
import TableBombas from './tabs/TableBombas';

import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '../../components/ui/accordion';

import {
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
} from '../../components/ui/tabs';

const ListEquipamentos = ({ unidadeID }) => {
	const [regimes, setRegimes] = useState([]);
	const [condensacao, setCondensacao] = useState([]);

	const fetchData = useCallback(async () => {
		try {
			const res = await apiRegimes.getByUnidadeId(unidadeID);
			setCondensacao(
				res.data
					.filter(function (regime) {
						return regime.tipoRegime === 'Condensação';
					})
					.map(function (regime) {
						return { id: regime.id, nome: regime.nome };
					})
			);

			setRegimes(
				res.data
					.map(function (regime) {
						return { id: regime.id, nome: regime.nome };
					})
			);
		} catch (error) {
			console.log(
				`Error fetching Equipamentos [Sobre Unidade - ListEquipamentos]: ${error}`
			);
		}
	}, [unidadeID]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const TabItems = {
		item1: [
			'Compressores',
			<TableCompressores
				regimes={regimes}
				condensacao={condensacao}
			/>,
		],
		item2: [
			'Condensadores',
			<TableCondensadores condensacao={condensacao} />,
		],
		item3: ['Sistemas de Água', <TableSistemas regimes={regimes} />],
		item4: [
			'Máquinas de Gelo',
			<TableMaquinasGelo regimes={regimes} />,
		],
		item5: [
			'Bombas',
			<TableBombas
				unidadeId={unidadeID}
				regimes={regimes}
				condensacao={condensacao}
			/>,
		],
		item6: [
			'Ventiladores',
			<TableVentiladores
				unidadeId={unidadeID}
				regimes={regimes}
				condensacao={condensacao}
			/>,
		],
		/* item7: ['Evaporadores', <TableEvaporadores regimes={regimes} />], 
        @ViniciusMarchand - @ViniciusMarchandCESS não tem uma API de evaporadores por regime. fazer isso no backend;
        */
	};

	const [activeTab, setActiveTab] = useState(
		Object.keys(TabItems)[0].toLowerCase()
	);
	useEffect(() => {}, [activeTab]);
	return (
		<CardLayout>
			<Accordion
				type="single"
				defaultValue="item-1"
				collapsible="true"
			>
				<AccordionItem value="item-1">
					<div className="font-semibold">
						<AccordionTrigger>
							<TitleCard>
								Equipamentos
								<p className="text-lg lg:text-[1.6rem] px-2">
									<FaTools />
								</p>
							</TitleCard>
						</AccordionTrigger>
					</div>
					<AccordionContent>
						<div className="flex flex-col w-full min-h-[10rem]">
							<Tabs
								defaultValue={Object.keys(
									TabItems
								)[0].toLowerCase()}
								className="flex mx-auto w-full flex-col"
							>
								<TabsList>
									<div className="flex flex-col lg:flex-row h-full w-full items-center justify-center">
										{Object.keys(
											TabItems
										).map(
											(
												key,
												index
											) => (
												<TabsTrigger
													className="lg:px-2 2xl:mx-6 5xl:mx-16 5xl:text-2xl rounded-md"
													key={
														index
													}
													value={key.toLowerCase()}
													onClick={() =>
														setActiveTab(
															key.toLowerCase()
														)
													}
												>
													{
														TabItems[
															key
														][0]
													}{' '}
												</TabsTrigger>
											)
										)}
									</div>
								</TabsList>
								{Object.keys(TabItems).map(
									(key, index) => (
										<TabsContent
											className={`${
												activeTab ===
												key
													? 'opacity-1 transform translate-y-[0px] transition-all duration-500'
													: 'opacity-0 transform translate-y-[-20px] transition-all duration-500'
											}`}
											key={index}
											value={key.toLowerCase()}
										>
											<div className="flex flex-col w-full justify-center items-center">
												{
													TabItems[
														key
													][1]
												}
											</div>
										</TabsContent>
									)
								)}
							</Tabs>
						</div>
					</AccordionContent>
				</AccordionItem>
			</Accordion>
		</CardLayout>
	);
};

export default ListEquipamentos;
